import React from "react";
import LokalitaTemplate from "../../templates/LokalitaTemplate";
import { StaticQuery, graphql, Link } from "gatsby"
import RegionyTemplate from "../../templates/RegionyTemplate";
import UniversalTemplate from "../../templates/UniversalTemplate";
import CenovaKalkulacka from "../../components/CenovaKalkulacka";



const faqData = [
  {
    otazka: "Co všechno obsahuje cena?",
    odpoved: `Ta obsahuje vyzvednutí zesnulého kdekoliv po Brně a blízkého okolí, převezení do krematoria, samotný žeh a následné uložení do dočasné urny. Doručení urny do vlastních rukou kamkoliv po Brně. V ceně je také administrativa spojená s vyřízením <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a> a možnost obracet se na naši zákaznickou linku 24 hodin 7 dní v týdnu.`
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.`
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];


export const query = graphql`
  query {
    brnoJihlavska: file(relativePath: { eq: "brno-jihlavska.jpg" }) {
      childImageSharp {
        fixed(width: 350, height: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

const BrnoJihlavska = ({data}) => {


  return(
    <UniversalTemplate 
      h1="Pohřební služba Brno Jihlavská"
      title={"Pohřební služba Brno Jihlavská: Empatická & Dostupné ceny"}
      desc="Jsme pohřební služba Brno poblíž Jihlavské ulice, u Fakultní nemocnice, Domově pro seniory Mikuláškovo náměstí nebo kdekoli jinde v Brně."
      perex={<>Pokud váš blízký zemřel poblíž Jihlavské ulice, ve Fakultní nemocnici Brno, v Domově pro seniory Mikuláškovo náměstí nebo u vás doma kdekoli jinde po Brně, můžete se obrátit na naši pohřební službu. Jsme vám k dispozici na telefonu 24 hodin 7 dní v týdnu.</>}
      imgFixed={data.brnoJihlavska.childImageSharp.fixed}
      imgAlt="Fakultní nemocnice, Brno Bohunice"
      breadscrumStranky={[
        {name: "Domů", url: "/"},
        {name: "Pohřební služba Brno", url: "/pohrebni-sluzba-brno/"},
        {name: "Pohřební služba Brno Jihlavská", url: "/pohrebni-sluzba-brno/jihlavska/"}
      ]}
      faqData={faqData}
      mesto="Brno"
      seVsimText={"Chápeme, že smrt blízkého je situace, kterou neplánujete. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení v Brně v Jihlavské ulici a okolí."}
      
      content={<>
        <p>Jsme spolehlivá <Link to="/pohrebni-sluzba-brno/">pohřební služba v Brně</Link>, která se postará o důstojné poslední rozloučení s vaším blízkým. Pomůžeme vám vše vyřídit přes internet z bezpečí vašeho domova. Můžete se na nás obrátit ať máte zájem o obřad v jedné z brněnských obřadních síní nebo kremaci bez obřadu. Pohřeb je možné zajistit například ve <Link to="/obradni-sine/velka-obradni-sin-krematoria-mesta-brna/">Velké obřadní síni krematoria Brna</Link> na Jihlavské ulici nebo v <Link to="/obradni-sine/smutecni-sin-zbysov/">obřadní síni Zbýšov</Link>.</p>
        <p>Pokud váš blízký zemřel ve Fakultní nemocnici Brno, kontaktujte nás a my už se s nemocnicí domluvíme na jeho vyzvednutí. V případě <Link to="/poradna/umrti-blizke-osoby-pruvodce/">úmrtí doma</Link> se můžete obrátit na naši nonstop linku a do několika málo hodin k vám přijedeme, ať bydlíte v ulici Jihlavská, Bohunicích, Starém či Novém Lískovci nebo v Kohoutovicích.</p>

        <CenovaKalkulacka compact />

        <h2>Jaký je postup?</h2>
        
        <h3>Nezávazně si zavoláme a probereme vaši situaci</h3>
        <p>Chápeme, že smrt blízkého je událost, kterou neplánujete. Pokud nám zavoláte, jsme připraveni vaši situaci okamžitě řešit. Probereme spolu vaši představu a pomůžeme vám najít ideální řešení pro vás a vaši rodinu, ať už se bude jednat o kremaci bez obřadu nebo rozloučení v některé z brněnských obřadních síní. Na telefonu jsme pro vás každý den, ve dne i v noci.</p>
        
        <h3>Pohřeb s námi naplánujete z bezpečí domova</h3>
        <p>Na plánování budete mít dostatek času a nemusíte nikam chodit. Vše s vámi vyřešíme přes e-mail a telefon. Bez zbytečného tlaku a stresu si tak můžete rozmyslet všechny detaily obřadu od <Link to="/poradna/smutecni-kytice-pohreb/">květinové výzdoby</Link> přes hudbu až po ozdobný obal na urnu nebo <Link to="/slovnik-pojmu/pamatecni-predmety/">památeční předmět</Link> vytvořený z popela. My jsme vám k dispozici během celého procesu, abychom vám maximálně pomohli a situaci ulehčili.</p>
        
        <h3>O vše ostatní se už postaráme my</h3>
        <p>Ať už si vyberete jakýkoliv pohřeb, naší prioritou je, abyste se mohli s vaším blízkým rozloučit důstojně a podle vašeho přání. My jsme tu, abychom vyřešili formality na matrice, oblékání a úpravu zesnulého, přípravu smuteční síně a další náležitosti. V rámci služby Péče+ vám také osobně dodáme vytištěné parte a doručíme urnu kamkoliv po Brně. Můžeme se postarat také o převod digitálních účtů vašeho blízkého.</p>

        <h2>V čem jsme jiní než ostatní pohřební služby na Jihlavské ulici v Brně?</h2>

        <h3>Vše vyřešíme přes internet</h3>
        <p>Ať váš blízký zemřel doma, ve Fakultní nemocnici Brno, v Domově pro seniory Mikuláškovo náměstí nebo kdekoli jinde v Brně, nemusíte nikam chodit. Vše vyřídíte z bezpečí vašeho domova nejen na Jihlavské ulici. Tam si můžete detaily posledního rozloučení v klidu rozmyslet a probrat je s rodinou.</p>
        
        <h3>Vždy za transparentní ceny</h3>
        <p>Cena, na které se domluvíme, je vždy konečná. Obsahuje kompletně vše, co budete potřebovat, včetně paušálních kilometrů po celém Brně.</p>
        
        <h3>Poskytneme vám speciální péči</h3>
        <p>Vytiskneme pro vás parte a zavezeme vám jej kamkoliv po Brně vč. ulice Jihlavská. Vyřídíme komunikaci s matrikou pro vystavení <Link to="/slovnik-pojmu/umrtni-list/">úmrtního listu</Link>. Osobně vám dovezeme urnu domů nebo na místo podle vašeho přání.</p>
        
        <p>Jako další vás mohou zajímat lokality:</p>
        <ul>
          <li><Link to="/pohrebni-sluzba-brno/lisen/">Pohřební služba Brno Líšeň</Link></li>
          <li><Link to="/pohrebni-sluzba-brno/koliste/">Pohřební služba Brno Koliště</Link></li>
          <li><Link to="/pohrebni-sluzba-brno/bohunice/">Pohřební služba Brno Bohunice</Link></li>
          <li><Link to="/pohrebni-sluzba-brno/venkov/">Pohřební služba Brno Venkov</Link></li>
          <li><Link to="/pohrebni-sluzba-brno/videnska/">Pohřební služba Brno Vídeňská</Link></li>
        </ul>
      </>}
    />
  );
}


export default BrnoJihlavska;
